import React from 'react'
import Layout from '../components/layout'
import PriceContent from '../components/Pricings/PriceContent'
import Helmet from 'react-helmet'

const PricingPage = () => (
  <Layout>
    <Helmet>
      <title>Pricings | ONN (by Curvo)</title>
    </Helmet>
    <PriceContent />
  </Layout>
)

export default PricingPage
