import { ApolloProvider } from '@apollo/client'
import { CategoriesData, GroupByEnum, NewslettersData, TagsData } from '@curvo/apollo'
import { Container, Flex, InfiniteScroll, NormalText, Spinner } from '@curvo/common-ui'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { MAX_SMALL_IPAD_SCREEN_WIDTH } from '../components/GlobalStyles'
import {
  NewsCategorySelector,
  NewsPage,
  NewsSearch,
  NewsSortBySelector,
  NewsletterTitle,
  TagsContainer,
} from '../components/Newsletter'
import Layout from '../components/layout'
import Client from '../config/Apollo'

/////////////////////////
// styled components
/////////////////////////
const FilterSearchContainer = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 48px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    flex-direction: column;
    margin-bottom: 32px;
  }
`

const ReleaseScheduleContainer = styled(Container)`
  margin-bottom: 64px;

  & > ul {
    margin-left: 16px;

    & > li {
      font-size: 18px;
      margin-bottom: 2px;
    }
  }
`
/////////////////////////
// component classes
/////////////////////////
type Props = {}
type State = {
  sortType: GroupByEnum
  categoryFilter: string | undefined
  tagFilter: string | undefined
  searchText: string | undefined
}
class NewsletterPage extends React.Component<Props, State> {
  newslettersScrollRef: InfiniteScroll | null = null

  readonly state = {
    sortType: GroupByEnum.Date,
    categoryFilter: undefined,
    tagFilter: undefined,
    searchText: undefined,
  }

  setSortType = (sortType: string) => {
    if (this.newslettersScrollRef) {
      this.newslettersScrollRef.scrollTo(0)
    }
    this.setState({ sortType: sortType as GroupByEnum })
  }
  setTagFilter = (tagFilter: string | undefined) => {
    if (this.newslettersScrollRef) {
      this.newslettersScrollRef.scrollTo(0)
    }
    this.setState({ tagFilter })
  }
  setCategoryFilter = (categoryFilter: string | undefined) => {
    if (this.newslettersScrollRef) {
      this.newslettersScrollRef.scrollTo(0)
    }
    this.setState({ tagFilter: undefined, categoryFilter })
  }
  onChangeSearchText = (searchText: string | undefined) => {
    if (this.newslettersScrollRef) {
      this.newslettersScrollRef.scrollTo(0)
    }
    this.setState({ searchText })
    this.setTagFilter(undefined)
  }

  render() {
    const { searchText, sortType, categoryFilter, tagFilter } = this.state
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Newsletter | ONN (by Curvo)</title>
        </Helmet>
        {
          /////////////////////////////////////////////////////////////////////
        }
        <ApolloProvider client={Client.getClient()}>
          <NewsletterTitle />
          <ReleaseScheduleContainer flexDirection="column">
            <NormalText className="heading">
              2024 Newsletter Release Schedule
            </NormalText>
            <ul>
              <li>March: <span style={{ fontFamily: 'metropolissemi_bold', fontStyle: 'italic', fontSize: '1rem' }}>New</span> Curvo Report</li>
              <li>April: Extremity Newsletter</li>
              <li>June: Trauma Newsletter</li>
              <li>August: Hip & Knee Newsletter</li>
              <li>October: Spine Newsletter</li>
            </ul>
          </ReleaseScheduleContainer>
          <Container flexDirection="column">
            <FilterSearchContainer>
              <NewsSortBySelector sortType={sortType} onSelect={this.setSortType} />
              <CategoriesData fetchPolicy="network-only">
                {({ data, loading, error }) => {
                  if (error) {
                    console.error('CATEGORY', error)
                    return <div />
                  }
                  if (!data || loading) {
                    return (
                      <NewsCategorySelector
                        category={categoryFilter}
                        categoriesList={[]}
                        onSelect={this.setCategoryFilter}
                      />
                    )
                  }
                  return (
                    <React.Fragment>
                      {data && data.categories && (
                        <NewsCategorySelector
                          category={categoryFilter}
                          categoriesList={data.categories.edges}
                          onSelect={this.setCategoryFilter}
                        />
                      )}
                    </React.Fragment>
                  )
                }}
              </CategoriesData>

              <NewsSearch onChangeText={this.onChangeSearchText} />
            </FilterSearchContainer>
            {
              /////////////////////////////////////////////////////////////////////
            }
            <TagsData
              variables={{
                categoryId: categoryFilter,
                searchText,
              }}
              fetchPolicy="network-only">
              {({ data, loading, error }) => {
                if (error) {
                  console.error('TAG', error)
                  return <div />
                }
                if (!data || loading) {
                  return (
                    <React.Fragment>
                      <NormalText className="heading" marginBottom="28px">
                        Tags
                      </NormalText>
                      <Spinner />
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment>
                    {data && data.countTags && data.countTags.edges && data.countTags.edges.length ? (
                      <React.Fragment>
                        <NormalText className="heading" marginBottom="28px">
                          Tags
                        </NormalText>
                        <TagsContainer data={data.countTags.edges} returnTag={this.setTagFilter} />
                      </React.Fragment>
                    ) : (
                      <Flex marginBottom="86px">
                        <NormalText className="heading">Tags</NormalText>
                        <NormalText secondary marginLeft="1em">
                          No tag
                        </NormalText>
                      </Flex>
                    )}
                  </React.Fragment>
                )
              }}
            </TagsData>
          </Container>
          {
            /////////////////////////////////////////////////////////////////////
          }
          <NewslettersData
            fetchPolicy="network-only"
            variables={{
              group: sortType,
              tagId: tagFilter,
              categoryId: categoryFilter,
              searchText,
            }}>
            {({ data, loading, error }, { fetchMore }) => {
              if (error) {
                console.error('NEWSLETTER', error)
                return <div />
              }

              return (
                <Container>
                  <InfiniteScroll
                    ref={ref => (this.newslettersScrollRef = ref)}
                    height="90vh"
                    marginBottom="86px"
                    loadMore={() => {
                      if (!loading && fetchMore) {
                        fetchMore()
                      }
                    }}
                    hasMore={!loading && data && data.newsletters && data.newsletters.pageInfo.hasNextPage}>
                    {data && data.newsletters && data.newsletters.edges && data.newsletters.edges.length ? (
                      <NewsPage newsletters={data.newsletters} sortBy={sortType} />
                    ) : (
                      <Container flexDirection="column" alignItems="center">
                        <NormalText secondary marginBottom="48px">
                          No Newsletter
                        </NormalText>
                      </Container>
                    )}
                    {loading && (
                      <Container>
                        <NormalText className="medium-title" margin="0 auto 80px auto">
                          Loading News <Spinner marginLeft="2em" />
                        </NormalText>
                      </Container>
                    )}
                  </InfiniteScroll>
                </Container>
              )
            }}
          </NewslettersData>
        </ApolloProvider>
      </Layout>
    )
  }
}

export default NewsletterPage
